<template>
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-user"></i>
        Хувийн мэдээлэл
<!--        <label class="btn btn-sm btn-outline btn-accent bg-[#0000008f] min-w-[148px]">-->
<!--            <i class="uil uil-image-v"></i>-->
<!--            <span class="pl-1 normal-case">Change cover</span>-->
<!--            <input type='file' class="hidden"/>-->
<!--        </label>-->
      </header>
      <form class="card-body w-full" :model="ruleForm" :rules="rules" ref="ruleForm">

<!--        <div class="absolute right-0">-->
<!--          <div class="fixed justify-end mt-[-560px] sm:mt-[-560px] md:mt-[-140px] ml-[-160px] md:ml-[-260px]">-->
<!--            <label class="btn btn-sm btn-outline btn-accent bg-[#0000008f] min-w-[148px]">-->
<!--              <i class="uil uil-image-v"></i>-->
<!--              <span class="pl-1 normal-case">Change cover</span>-->
<!--              <input type='file' class="hidden"/>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->

        <div class="md:flex md:items-center mb-6 p-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Зураг:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <div class="m-1">
              <div class="flex items-center justify-center md:w-3/4">
                <label
                    class="flex flex-col w-full h-32 border-2 border-dashed hover:bg-base-200 hover:border-accent-focus">
                  <div class="flex flex-col items-center justify-center pt-7">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         class="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                         viewBox="0 0 20 20"
                         fill="currentColor">
                      <path fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"/>
                    </svg>
                    <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Зургаа сонгоно уу.
                    </p>
                  </div>
                  <form method="post" action="/lambda/krud/upload">
                    <input type="file" name="file" :model="ruleForm.avatar" @change="uploadImage($event)" class="opacity-0" accept="image/*"/>
                  </form>
<!--                  <input type="file" v-model="ruleForm.avatar" class="opacity-0"/>-->
                </label>
              </div>
              <label class="inline-block mt-2 text-gray-500">Upload
                Image(jpg,png,svg,jpeg)</label>
            </div>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Нэр:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Таны нэр"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.firstname">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Овог:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Таны овог"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.lastname">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">И-мэйл:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="И-мэйл хаяг"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.email">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Утас:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Утасны дугаар"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.phone">
          </div>
        </div>
<!--        <div class="md:flex md:items-center mb-6">-->
<!--          <div class="md:w-1/3">-->
<!--            <label class="block md:text-right mb-1 md:mb-0 pr-4">-->
<!--              <span class="label-text">Хүйс:</span>-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="md:w-2/3">-->
<!--            <div class="w-1/2 flex gap-4 w-full md:w-3/4">-->
<!--              <label class="cursor-pointer label basis-1/2">-->
<!--                <input type="radio" name="gender" checked="checked"-->
<!--                       class="radio radio-accent radio-xs" value="" v-model="ruleForm.gender">-->
<!--                <span class="label-text pl-2">Эрэгтэй</span>-->
<!--              </label>-->
<!--              <label class="cursor-pointer label basis-1/2">-->
<!--                <input type="radio" name="gender" class="radio radio-accent radio-xs"-->
<!--                       value="" v-model="ruleForm.gender">-->
<!--                <span class="label-text pl-2">Эмэгтэй</span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="w-full">
            <Button class="mt-5 bg-[#e67e22] hover:bg-[#E67E22FF]" @click="submitForm">
              <div class="text-center w-full">Хадгалах</div>
            </Button>
            <Button class="mt-5 bg-[#e67e22] hover:bg-red" @click="resetForm">
              <div class="text-center w-full">Болих</div>
            </Button>
<!--            <Button label="Хадгалах" class="p-button-sm mt-1 ml-1 text-white" @click="submitForm('ruleForm')"/>-->
<!--            <Button label="Болих" class="p-button-sm mt-1 ml-1" @click="resetForm('ruleForm')"/>-->
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'ProfileForm',
  components: {

  },
  data() {
    return {
      ruleForm: {
        userId: '',
        coverImage: '',
        avatar: null,
        firstname: '',
        lastname: '',
        nickename: '',
        phone: '',
        gender: '',
        dialogImageUrl: '',
        dialogVisible: false
      },
      rules: {
        firstname: [
          {required: true, message: 'Нэрээ оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны нэр 3-5 тэмдэгт орсон байх ёстой.', trigger: 'blur'}
        ],
        lastname: [
          {required: true, message: 'Овгоо оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны овог 3-5 тэмдэгт орсон байх ёстой.', trigger: 'blur'}
        ],
        nickename: [
          {required: true, message: 'Хочоо оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны хоч 3-5 тэмдэгт орсон байх ёстой.', trigger: 'blur'}
        ],
        // gender: [
        //   {required: true, message: 'Хүйсээ сонгоно уу!', trigger: 'change'}
        // ]
      }
    };
  },

  computed: {
    ...mapGetters(['isLoadingAuth', 'user'])
  },

  created() {
    console.log('user information');
    this.ruleForm.userId = this.user.id;
    this.ruleForm.avatar = this.user.avatar;
    this.ruleForm.firstname = this.user.first_name;
    this.ruleForm.lastname = this.user.last_name;
    this.ruleForm.email = this.user.email;
    // this.ruleForm.gender = this.user.gender;
    this.ruleForm.phone = this.user.phone;
    console.log(this.user);
  },

  methods: {
    ...mapActions(['saveUserInfo']),
    uploadImage(e) {
      console.log('image');
      const file = e.target.files[0];
      this.ruleForm.avatar = file;
      console.log(file);
      console.log(this.ruleForm);
    },
    submitForm() {
      // this.$refs.ruleForm.validate((valid) => {
      //   if (valid) {
          this.saveUserInfo(({vm: this.ruleForm}))
        // } else {
        //   console.log('error submit!!');
        //   return false;
        // }
      // });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  },
};
</script>
<style>
.email-form {
  color: #9697a3;
  height: 44px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  outline-color: #e67e22;
  background-color: rgba(35, 45, 64, 0.5);
}
</style>
