<template>

  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="bg-base-100 p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-unlock-alt"></i>
        Пин солих
      </header>
      <form class="card-body w-full p-6" :rules="rules" ref="ruleForm" :model="ruleForm">
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Хуучин пин:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="password" placeholder="Хуучин пин" v-model="ruleForm.oldPass"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Шинэ пин:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="password" placeholder="Шинэ пин" v-model="ruleForm.pass"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Шинэ пин:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="password" placeholder="Шинэ пин давтах" v-model="ruleForm.checkPass"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form">
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="w-full">
            <Button class="mt-5 bg-[#e67e22] hover:bg-[#E67E22FF]" @click="submitForm('ruleForm')">
              <div class="text-center w-full">Хадгалах</div>
            </Button>
            <Button class="mt-5 bg-[#e67e22] hover:bg-red" @click="resetForm('ruleForm')">
              <div class="text-center w-full">Болих</div>
            </Button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>

export default {
  components: {

  },
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Хуучин пинээ оруулна уу!'));
      } else {
        // if (this.ruleForm.checkPass !== '') {
        //     this.$refs.ruleForm.validateField('checkPass');
        // }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Шинэ пинээ оруулна уу!'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Шинэ пинээ дахин оруулна уу!'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('Шинэ пин зөрүүтэй байна!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        oldPass: [
          { validator: validateOldPass, trigger: 'blur' }
        ],
        ass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },

  computed: {

  },

  created() {

  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>
