<template>

  <!-- main content -->
  <main class="page">
    <div class="page-header large-header podcast">
      <div class="header-layer">
        <div class="container">
          <div class="header-title">
            <small>Business & podcast</small>
            <h3>Подкаст</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="padding-top: 30px;">
      <div v-if="isLoadingPodcast" style="display: flex; flex-direction: row; margin-top: 20px">
        <div style="width:240px; height: 190px; background: grey; border-radius: 8px"/>
        <div style="width:240px; height: 190px; background: grey; margin-left: 30px; border-radius: 8px"/>
        <div style="width:240px; height: 190px; background: grey; margin-left: 30px; border-radius: 8px"/>
        <div style="width:240px; height: 190px; background: grey; margin-left: 30px; border-radius: 8px"/>
        <div style="width:240px; height: 190px; background: grey; margin-left: 30px; border-radius: 8px"/>
      </div>

      <div v-else class="media-grid-wrapper">
        <div class="grid gap-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-3 cs-style-3"
             v-if="podcastAlbumList.length > 0">
          <div v-for="(item, index) in podcastAlbumList" :key="index">
            <div class="album" >
              <div class="album__cover">
                <img :src="imageUrl(item.image, true)" alt="image" style="height: 240px; object-fit: cover"/>
                <div class="play_btn" @click="detail(item.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"/>
                  </svg>
                </div>
                <span class="album__stat">
										<span style="font-size: 16px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                        d="M3.71,16.29a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21,1,1,0,0,0-.21.33,1,1,0,0,0,.21,1.09,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1,1,0,0,0,.21-1.09A1,1,0,0,0,3.71,16.29ZM7,8H21a1,1,0,0,0,0-2H7A1,1,0,0,0,7,8ZM3.71,11.29a1,1,0,0,0-1.09-.21,1.15,1.15,0,0,0-.33.21,1,1,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1,1,0,0,0,3.71,11.29ZM21,11H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2ZM3.71,6.29a1,1,0,0,0-.33-.21,1,1,0,0,0-1.09.21,1.15,1.15,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21,1,1,0,0,0,1.09-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1.15,1.15,0,0,0,3.71,6.29ZM21,16H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/></svg> {{
                        item.podcast_count
                      }}</span>
									</span>
              </div>
              <div class="album__title">
                <div class="at_btn" @click="detail(item.id)">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
  <!-- end main content -->
  <ConfirmDialog></ConfirmDialog>
  <Dialog :showHeader="false" class="login-page-modal" v-model:visible="isAuthVisible" :modal="true"
          :dismissableMask="true"
          position="center" :closable="true" :style="{width: '600px'}">
    <Login :hide="hideAuthModal"/>
  </Dialog>

</template>
<script>
import {imageUrl} from "../../utils";
import api from "../../store/_boot/baseApi"
import {mapGetters} from "vuex";
import Login from "@pages/auth/login.vue";

export default {
  components: {Login},
  data() {
    return {
      isAuthVisible: false,
      catList: [],
      // conf: useRuntimeConfig(),
      cat_id: 0,
      podcastAlbumList: [],
      // config: useRuntimeConfig(),
      isLoading: false,
      isLoadingPodcast: false,
    }
  },
  created() {
    this.catListData();
    this.catFilterPodcastAlbum();
  },
  computed: {
    ...mapGetters([ 'user']),
  },
  methods: {
    showAuthModal() {
      this.isAuthVisible = true;
    },
    hideAuthModal() {
      this.isAuthVisible = false;
    },
    expireConfirmDialog() {
      this.$confirm.require({
        message: 'Та эрхээ сунгаж тус контентыг үзнэ үү !!',
        header: 'Эрх сунгах',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Save'
        },
        acceptLabel:"Эрх сунгах",
        rejectLabel:"Болих",
        accept: () => {
          this.$router.push('/payment');
        },
      });
    },

    imageUrl,
    setCatId(id) {
      console.log("cat id ---------->", id);
      this.cat_id = id;
      this.catFilterPodcastAlbum()
    },
    catListData() {
      this.isLoading = true;
      api.get('api/web/category')
          .then((data) => {
            this.catList = data.data.data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error fetching data:", error);
          });
    },


    detail(id) {
      console.log("dddd--->>")
      if(this.user == null){
        this.showAuthModal()
      } else {
        if (this.user.days === 0) {
          this.expireConfirmDialog()
        } else {
          this.$router.push(`/podcast/detail/${id}`);
        }
      }
    },

    catFilterPodcastAlbum() {
      this.isLoadingPodcast = true;
      api.get('api/web/cat-filter-podcast-album', {
        params: {
          'cat_id': this.cat_id
        }
      }).then((data) => {
        console.log("cat filter data--------->", data.data.data);
        this.podcastAlbumList = data.data.data;
        this.isLoadingPodcast = false;
      })
          .catch((error) => {
            this.isLoadingPodcast = false;
            console.error("Error fetching data:", error);
          });
    },
  }
}


</script>
