<template>
  <div class="login-page min-h-[480px]">
    <div class="login-page-hide">
      <Button icon="pi pi-times" severity="danger" rounded outlined aria-label="Cancel" @click="this.hide()"/>
    </div>
    <div class="login-body text-center p-6 mt-10 text-white">
      <div v-if="isLoading" class="spin-wrap">
        <ProgressSpinner/>
      </div>

<!--      <ul class="text-sm font-medium text-center  flex mt-[-56px] py-4" v-if="step === 1">-->
<!--        <li class="w-full focus-within:z-10">-->
<!--          <a href="#" @click="isChecker(true)" :class="islogin ? 'bg-[#e67e22]' : 'border-white-600'"-->
<!--             class="border-[1px] border-white-600 bg-white text-gray-900 inline-block w-full p-2 hover:bg-gray-100 rounded-l-md rounded-left hover:text-white hover:bg-[#e28737]">Нэвтрэх</a>-->
<!--        </li>-->
<!--        <li class="w-full focus-within:z-10">-->
<!--          <a href="#" @click="isChecker(false)" :class="islogin ? ' border-white-600' : 'bg-[#e67e22]'"-->
<!--             class="border-[1px] border-white-600 bg-white text-gray-900 inline-block w-full p-2 hover:bg-gray-100 rounded-r-md hover:text-white hover:bg-[#e28737]">Бүртгүүлэх</a>-->
<!--        </li>-->
<!--      </ul>-->

      <div v-if="step === 1" class="step1">
        <h1 class="mt-[-20px] pb-5 text-2xl text-[#e67e22]">Нэвтрэх</h1>
        <p class="text-white">Утасны дугаараа хийгээд Үргэлжлүүлэх товчлуур дээр дарна уу</p>
        <br>
        <div class="verify-number">
          <CodeInput
              ref="cinput"
              :fields="8"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onComplete"
              v-on:keyup="keyNumberEnter"
          />
        </div>
        <div class="mt-28">
          <label for="checkbox" style="font-size:12px;">Та шинээр бүртгэл үүсгэх бол <a href="#" @click="backModal(8)"
                                                           style="color: #e67e22; text-decoration: underline;">ЭНД</a>
            дарна уу.</label>
          <Button class="mt-5 w-full tail-red-button" @click="checkPhone({vm: this})">
            <div class="text-center w-full">Үргэлжлүүлэх</div>
          </Button>

<!--          <Button v-else class="mt-5 w-full tail-red-button" @click="codeSendPhone({vm: this})">-->
<!--            <div class="text-center w-full">Үргэлжлүүлэх</div>-->
<!--          </Button>-->
          <!--          <Button v-else href="#register-modal" class="mt-5 w-full text-center p-button-outlined" @click="codeSendPhone({vm: this})">-->
          <!--            <div class="text-center w-full">Бүртгүүлэх</div>-->
          <!--          </Button>-->
        </div>
      </div>
      <div v-if="step === 8" class="step8">
        <h1 class="mt-[-20px] pb-5 text-2xl text-[#e67e22]">Бүртгүүлэх</h1>
        <button type="button" @click="backModal(1)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <p class="text-white">Утасны дугаараа хийгээд Бүртгүүлэх товчлуур дээр дарна уу</p>
        <br>
        <div class="verify-number">
          <CodeInput
              ref="cinput"
              :fields="8"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onComplete"
              v-on:keyup="keyNumberEnter"
          />
        </div>
        <div class="mt-28">

          <Button class="mt-5 w-full tail-red-button" @click="codeSendPhone({vm: this})">
            <div class="text-center w-full">Бүртгүүлэх</div>
          </Button>
          <!--          <Button v-else href="#register-modal" class="mt-5 w-full text-center p-button-outlined" @click="codeSendPhone({vm: this})">-->
          <!--            <div class="text-center w-full">Бүртгүүлэх</div>-->
          <!--          </Button>-->
        </div>
      </div>

      <div v-if="step === 2" class="step2">
        <div v-if="isLoadingCode" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(1)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <h2>Утасны дугаар: {{ this.phone }}</h2>
        <p>Та мессежээр ирсэн 4 оронтой кодоо оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <CodeInputVerify
              ref="vinput"
              :fields="4"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onCompleteVerify"
              v-on:keyup="keyCheckCodeEnter"
          />
        </div>

        <div class="mt-28 modal-action">
          <Button class="mt-5 w-full text-center tail-red-button" :class="isLoadingCode ? 'Loading' : ''"
                  @click="checkCode(({vm: this}))">
            <div class="text-center w-full">Код оруулах</div>
          </Button>

          <!--          <button class="btn btn-accent btn-outline mt-5">Пин сэргээх</button>-->
        </div>
      </div>

      <div v-if="step === 3" class="step3">
        <div v-if="isLoadingPin" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(2)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <p>Та өөрийн 4 оронтой пин кодоо оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <CodeInputVerify
              ref="pass"
              :fields="4"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onCompletePin"
              v-on:keypress="keySendPassEnter"
          />
        </div>

        <div class="mt-28 modal-action">
          <Button class="mt-5 w-full text-center tail-red-button" :class="isLoadingPin ? 'loading' : ''"
                  @click="sendPass(({vm: this}))">
            <div class="text-center w-full">Пин илгээх</div>
          </Button>
        </div>
      </div>

      <div v-if="step === 4" class="step4">
        <div v-if="isLoadingEmail" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(3)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <p>Та өөрийн имэйл хаягаа оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <input
              class="email-form"
              placeholder="Имэйл хаяг"
              type="email"
              v-model="email"
              @input="onEmail(email)"
              v-on:keypress="keyCompleteEmailEnter"
          />
        </div>

        <div class="mt-28 modal-action">
          <Button class="mt-5 w-full text-center tail-red-button" :class="isLoadingEmail ? 'loading' : ''"
                  @click="onCompleteEmail()">
            <div class="text-center w-full">Имэйл илгээх</div>
          </Button>
        </div>
      </div>

      <div v-if="step === 5" class="step5">
        <div v-if="isLoadingEmailCode" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(3)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <h2>Имэйл хаяг: {{ this.email }}</h2>
        <p>Та имэйл хаягаар ирсэн кодоо оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <CodeInputVerify
              ref="emailCheck"
              :fields="6"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onCompleteEmailVeriry"
              v-on:keypress="keyCheckEmailCodeEnter"/>
        </div>

        <div class="mt-28 modal-action">
          <Button class="mt-5 w-full text-center tail-red-button" :class="isLoadingEmailCode ? 'loading' : ''"
                  @click="checkEmailCode(({vm: this}))">
            <div class="text-center w-full">Код оруулах</div>
          </Button>
        </div>
      </div>

      <div v-if="step === 6" class="step6">
        <div v-if="isLoadingVerify" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(1)" class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <p>Та өөрийн 4 оронтой пин кодоо оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <CodeInputVerify
              ref="pass"
              :fields="4"
              :fieldWidth="38"
              type="number"
              v-on:change="onChange"
              v-on:complete="onCompletePin"
              v-on:keypress="keyLoginEnter"
          />
        </div>

        <div class="mt-28 modal-action">
          <Button class="mt-[60px] w-full text-center tail-red-button" :class="isLoadingVerify ? 'loading' : ''"
                  @click="login(({vm: this}))">
            <div class="text-center w-full">Нэвтрэх</div>
          </Button>
        </div>
      </div>



      <div v-if="step === 7" class="step7">
        <div v-if="isLoadingVerify" class="spin-wrap">
          <ProgressSpinner/>
        </div>
        <button type="button" @click="backModal(1)"
                class="absolute mt-[-46px] ml-[-184px] border border-white-700 hover:bg-[#e67e22] hover:border-[#e67e22] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center me-2">
          <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"></path>
          </svg>
          Буцах
        </button>
        <p>Овог нэрээ оруулна уу</p>
        <br>
        <div class="verify-number pt-2">
          <input ref="lastNameRef"
              class="email-form"
              placeholder="Овог"
              type="text"
              v-model="lastName"
              @input="onLastName(lastName)"
              @keypress.enter="moveToNextInput($event, 'firstNameRef')"
          />
          <input style="margin-top: 10px; margin-bottom: 10px"
                 ref="firstNameRef"
                 class="email-form"
                 placeholder="Нэр"
                 type="text"
                 v-model="firstName"
                 @input="onFirstName(firstName)"
                 @keypress.enter="moveToNextInput($event, 'termsCheckRef')"
          />
        </div>
        <input ref="termsCheckRef" type="checkbox" id="checkbox" style="accent-color: #e67e22;" v-model="termsCheck" @keypress.enter="keySaveUserEnter"/>
        <label for="checkbox" style="font-size:12px;"><a href="" target="_blank"
                                                         style="color: #e67e22; text-decoration: underline; margin-left: 6px; ">"Үйлчилгээний
          нөхцөл"</a>
          -тэй бүрэн танилцсан ба зөвшөөрч байна.</label>

        <div class="mt-22 modal-action">
          <Button class="mt-[60px] w-full text-center tail-red-button"
                  @click="saveUserHHA(({vm: this}))">
            <div class="text-center w-full">Үргэлжлүүлэх</div>
          </Button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CodeInput from "@components/CodeInput";
import CodeInputVerify from "@components/CodeInputVerify";
import {mapActions} from "vuex";
// import {saveUserHHA} from "../../store/_actions/auth.action";

export default {
  components: {
    CodeInput,
    CodeInputVerify
  },
  props: ["hide"],
  data() {
    return {
      step: 1,
      count: 30,
      isLoading: false,
      isLoadingCode: false,
      isLoadingPin: false,
      isLoadingEmail: false,
      isLoadingVerify: false,
      isLoadingEmailCode: false,
      isLoadingReset: false,
      isLoadingNickname: false,
      btnLoading: false,
      phone: "",
      verify: "",
      code: "",
      email: "",
      emailCode: "",
      firstName: "",
      lastName: "",
      termsLink: "",
      timer: null,
      disableSendAgain: true,
      password: "",
      password_confirmation: "",
      nickname: "",
      userId: 0,
      islogin: true,
      termsCheck: false
    };
  },

  methods: {
// saveUserHHA,
    ...mapActions(['checkPhone', 'codeSendPhone', 'checkCode', 'sendPass', 'sendEmail', 'checkEmailCode', 'login', 'saveUserHHA']),

    next() {
      if (this.step++ > 2) this.step = 1;
    },

    isChecker(val) {
      this.islogin = val;
      // this.hide()
    },

    onComplete(val) {
      this.phone = val;
    },

    onCompletePin(val) {
      this.password = val;
    },

    onCompleteVerify(val) {
      this.verify = val;

    },

    onCompleteEmail() {
      if (this.email) {
        this.sendEmail(({vm: this}));
      } else {
        this.$Message.error('Fail!');
      }
    },

    onCompleteEmailVeriry(val) {
      this.code = val;
    },

    onEmail(val) {
      this.email = val;
    },
    onLastName(val) {
      this.lastName = val;
    },
    onFirstName(val) {
      this.firstName = val;
    },
    backModal(val) {
      this.step = val;
    },

    saveUserInformation(){

    },
    keyNumberEnter: function(e) {
      if (e.keyCode === 13) {
        if(this.islogin){
          this.checkPhone({vm: this})
        }else{
          this.codeSendPhone({vm: this})
        }
      }
    },
    keySendPassEnter: function(e) {
      if (e.keyCode === 13) {
        this.sendPass(({vm: this}))
      }
    },
    keyLoginEnter: function (e){
        if (e.keyCode === 13) {
          this.login(({vm: this}))
      }
    },
    keyCheckCodeEnter : function (e){
      if (e.keyCode === 13) {
        this.checkCode(({vm: this}))
      }
    },
    keyCompleteEmailEnter: function (e){
      if (e.keyCode === 13) {
        this.onCompleteEmail()
      }
    },
    keyCheckEmailCodeEnter: function (e){
      if (e.keyCode === 13) {
        this.checkEmailCode(({vm: this}))
      }
    },
    moveToNextInput(event, nextInputId) {
      if (event.key === 'Enter') {
        const nextInput = this.$refs[nextInputId];
        if (nextInput) {
          nextInput.focus();
        }
      }
    },
    keySaveUserEnter(event){
      if (event.key === 'Enter') {
        this.termsCheck = true
        this.saveUserHHA(({vm: this}))
      }
    }

  }
}
</script>

<style>
.email-form {
  color: white;
  height: 44px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  outline-color: #e67e22;
  background-color: rgba(35, 45, 64, 0.5);
}
</style>